import React from "react";
import "./loading.css";
import { useLoaderData } from "react-router-dom";

const Loading = () => {
  const json = useLoaderData();

  return (
    <div className="loading">
      <div className="logos-header">
        <img src="./assets/images/logo.png" alt="life-ribermine"></img>
        <img src="./assets/images/logo_life.png" alt="life"></img>
      </div>
      <div className="loader-container">
        <div className="loader">
          <div className="blocker"></div>
        </div>
        <h4 className="loading-label">{json.loading}</h4>
      </div>
      <div className="loader-info">
        <h6>{json.loadingTip}</h6>
        <img
          className="image-loading"
          src="./assets/images/img-scanner.png"
          alt=""
        ></img>
        <h6>{json.scanTip}</h6>
      </div>
    </div>
  );
};

export default Loading;

import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "./App.css";
import LandingPage from "./Components/landingPage";
import MindAR from "./Components/viewer";

export const availableLanguages = {
  en: "English",
  es: "Español",
  pt: "Português",
};

export const WALL_ES_VIDEO_URL = "https://vimeo.com/816857950";
export const WALL_PT_VIDEO_URL = "https://vimeo.com/816857586";

const App = () => {
  const languages = Object.keys(availableLanguages);
  let start = navigator.languages
    .find((l) => languages.includes(l.slice(0, 2).toLowerCase()))
    ?.slice(0, 2)
    .toLocaleLowerCase();
  start = start ? start : "pt";

  const router = createBrowserRouter(
    [
      {
        path: "/ar-videos/wall_es.html",
        element: <Navigate to={`/${start}/ar-videos/wall_es.html`} />,
      },
      {
        path: "/ar-videos/wall_pt.html",
        element: <Navigate to={`/${start}/ar-videos/wall_pt.html`} />,
      },
      {
        path: "/:lng/ar-videos/wall_es.html",
        element: <LandingPage wall="wall_es" />,
        loader: ({ params }) => {
          let json;
          try {
            json = require(`./Language/${params.lng}.json`);
          } catch (e) {
            throw new Response("Not found", { status: 404 });
          }

          return json;
        },
      },
      {
        path: "/:lng/ar-videos/wall_pt.html",
        element: <LandingPage wall="wall_pt" />,
        loader: ({ params }) => {
          let json;
          try {
            json = require(`./Language/${params.lng}.json`);
          } catch (e) {
            throw new Response("Not found", { status: 404 });
          }

          return json;
        },
      },
      {
        path: "/:lng/ar-videos/play/wall_es.html",
        element: <MindAR wall="wall_es" />,
        loader: ({ params }) => {
          let json;
          try {
            json = require(`./Language/${params.lng}.json`);
          } catch (e) {
            throw new Response("Not found", { status: 404 });
          }

          return json;
        },
      },
      {
        path: "/:lng/ar-videos/play/wall_pt.html",
        element: <MindAR wall="wall_pt" />,
        loader: ({ params }) => {
          let json;
          try {
            json = require(`./Language/${params.lng}.json`);
          } catch (e) {
            throw new Response("Not found", { status: 404 });
          }

          return json;
        },
      },
      {
        path: "/",
        element: <Navigate to={`/${start}/ar-videos/wall_pt.html`} />,
        errorElement: <Navigate to={`/${start}/ar-videos/wall_pt.html`} />,
      },
    ],
    { basename: "/" }
  );

  return <RouterProvider router={router} />;
};

export default App;

import React, { useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import "./landingPage.css";
import {
  WALL_ES_VIDEO_URL,
  WALL_PT_VIDEO_URL,
  availableLanguages,
} from "../App";

const LandingPage = ({ wall }) => {
  const navigate = useNavigate();

  const [languageMenu, openLanguageMenu] = useState(false);

  const { lng } = useParams();

  const json = useLoaderData();

  const toggleLanguageMenu = (open) => {
    // const nextLng = lng === "pt" ? "es" : "pt";

    // navigate(`/${nextLng}`);
    openLanguageMenu(open);
  };

  const selectLanguage = (newLng) => {
    navigate(`/${newLng}/ar-videos/${wall}.html`);
  };

  const startExperience = () => {
    navigate(`/${lng}/ar-videos/play/${wall}.html`);
  };

  const openVideoPlatform = (link) => {
    window.open(link);
  };

  const languageSpans = Object.keys(availableLanguages).map((l) => {
    return (
      <div
        key={`lng-${l}`}
        className="language-selector"
        onClick={() => {
          selectLanguage(l);
        }}
      >
        <span>{availableLanguages[l]}</span>
        <img src={`./assets/images/${l}.svg`} alt=""></img>
      </div>
    );
  });

  return (
    <div className="landing-page" onClick={() => toggleLanguageMenu(false)}>
      <div className="header">
        <div className="logos">
          <img src="./assets/images/logo.png" alt="life-ribermine"></img>
          <img src="./assets/images/logo_life.png" alt="life"></img>
        </div>
        <div
          className="language"
          onClick={(e) => {
            e.stopPropagation();
            toggleLanguageMenu(!languageMenu);
          }}
        >
          {lng.toUpperCase()}
          <span
            className="material-symbols-outlined"
            style={languageMenu ? { transform: "scale(-1)" } : {}}
          >
            arrow_drop_down
          </span>
          {languageMenu && (
            <div className="languages-container">{languageSpans}</div>
          )}
        </div>
      </div>
      <h1>{json.title}</h1>
      <button className="start" onClick={() => startExperience()}>
        {json.start}
      </button>
      <h6>{json.subtitle}</h6>
      <button
        className="link"
        onClick={() => {
          openVideoPlatform(
            wall === "wall_pt" ? WALL_PT_VIDEO_URL : WALL_ES_VIDEO_URL
          );
        }}
      >
        <span className="material-symbols-outlined">open_in_new</span>
        {json.link}
      </button>
    </div>
  );
};

export default LandingPage;

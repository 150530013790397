import React from "react";
import "./scanner.css";
import { useLoaderData } from "react-router-dom";

const Scanner = () => {
  const json = useLoaderData();

  return (
    <div className="scanner-container">
      <img
        className="scanner-corners"
        src="./assets/images/subtract.svg"
        alt=""
      ></img>
      <div className="scanner"></div>
      <span className="scanner-info">{json.scanTip}</span>
    </div>
  );
};

export default Scanner;

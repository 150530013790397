import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "./loading";
import Scanner from "./scanner";
import "./viewer.css";
import { WALL_ES_VIDEO_URL, WALL_PT_VIDEO_URL } from "../App";

const MindAR = ({ wall }) => {
  const navigate = useNavigate();

  const sceneRef = useRef(null);

  const videoEsRef = useRef(null);
  const entityEsRef = useRef(null);

  const videoPtRef = useRef(null);
  const entityPtRef = useRef(null);

  const resetTimeout = useRef(null);

  const [loaded, toggleLoaded] = useState(false);
  const [targetFound, toggleTargetFound] = useState(false);
  const [muted, toggleMute] = useState(true);
  const [landscape, changeOrientation] = useState(
    window.innerWidth > window.innerHeight
  );

  const { lng } = useParams();

  const logoClick = () => {
    navigate(`/${lng}/ar-videos/${wall}.html`);
  };

  /**
   * Reload Page when rotation detected
   * Optimal: Reset MindAR on rotation
   */
  useEffect(() => {
    const reset = () => {
      const sceneEl = sceneRef.current;
      const arSystem = sceneEl.systems["mindar-image-system"];
      toggleLoaded(false);
      changeOrientation(window.innerWidth > window.innerHeight);
      arSystem.stop();
      setTimeout(() => {
        arSystem.start();
      }, 1000);
    };
    window.addEventListener("orientationchange", reset);

    return () => {
      window.removeEventListener("orientationchange", reset);
    };
  }, []);

  /**
   * Load Events
   */
  useEffect(() => {
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems["mindar-image-system"];
    sceneEl.addEventListener("renderstart", () => {
      arSystem.start(); // start AR
    });
    sceneEl.addEventListener("arReady", () => {
      toggleLoaded(true);
    });

    return () => {
      arSystem.stop();
    };
  }, []);

  /**
   * Target found/lost events
   */
  useEffect(() => {
    const targetEvents = (entity, video) => {
      entity.addEventListener("targetFound", () => {
        toggleTargetFound(true);
        video.play();
        clearTimeout(resetTimeout.current);
      });
      entity.addEventListener("targetLost", () => {
        toggleTargetFound(false);
        video.pause();
        resetTimeout.current = setTimeout(() => {
          video.currentTime = 0;
        }, 1500);
      });
    };

    targetEvents(entityEsRef.current, videoEsRef.current);
    targetEvents(entityPtRef.current, videoPtRef.current);
  }, []);

  const openVimeo = (link) => {
    window.open(link);
  };

  return (
    <div className="container">
      <a-scene
        ref={sceneRef}
        mindar-image="imageTargetSrc: ./assets/mind/es_pt_single.mind; filterMinCF: 0.001; filterBeta: 1000; uiError:no; uiLoading:no; uiScanning: no; autoStart: false"
        color-space="sRGB"
        renderer="colorManagement: true, physicallyCorrectLights"
        vr-mode-ui="enabled: false"
        device-orientation-permission-ui="enabled: false"
        embedded
      >
        <a-assets>
          <video
            ref={videoPtRef}
            id="vid-pt"
            src="./assets/videos/pt_v3.mp4"
            crossOrigin="anonymous"
            loop
            muted={muted}
            preload="auto"
            playsInline
          ></video>
          <video
            ref={videoEsRef}
            id="vid-es"
            src="./assets/videos/es_v3.mp4"
            crossOrigin="anonymous"
            loop
            muted={muted}
            preload="auto"
            playsInline
          ></video>
          <img id="btn-v-on" src="./assets/images/btn_v_on.png" alt=""></img>
          <img id="btn-v-off" src="./assets/images/btn_v_off.png" alt=""></img>
          <img id="btn-link" src="./assets/images/btn_link.png" alt=""></img>
        </a-assets>

        <a-camera
          position="0 0 0"
          look-controls="enabled: false"
          cursor="fuse: false; rayOrigin: mouse;"
          raycaster="far: 100000; objects: .clickable"
        ></a-camera>

        <a-entity ref={entityEsRef} mindar-image-target="targetIndex: 0">
          <a-plane
            position="0 0 0"
            height="0.67"
            width="1"
            rotation="0 0 0"
            color="#000000"
          ></a-plane>
          <a-video
            src="#vid-es"
            position="0 0 0.001"
            height="0.56"
            width="1"
            rotation="0 0 0"
          ></a-video>
          {!muted && (
            <a-image
              src="#btn-v-on"
              class="clickable"
              position={landscape ? "0.55 0.1 0" : "-0.1 -0.45 0"}
              height="0.15"
              width="0.15"
              rotation="0 0 0"
              onClick={() => {
                toggleMute(true);
              }}
            ></a-image>
          )}
          {muted && (
            <a-image
              src="#btn-v-off"
              class="clickable"
              position={landscape ? "0.6 0.1 0" : "-0.1 -0.45 0"}
              height="0.15"
              width="0.15"
              rotation="0 0 0"
              onClick={() => {
                toggleMute(false);
              }}
            ></a-image>
          )}
          <a-image
            src="#btn-link"
            class="clickable"
            position={landscape ? "0.6 -0.1 0" : "0.1 -0.45 0"}
            height="0.15"
            width="0.15"
            rotation="0 0 0"
            onClick={() => {
              openVimeo(WALL_ES_VIDEO_URL);
            }}
          ></a-image>
        </a-entity>

        <a-entity ref={entityPtRef} mindar-image-target="targetIndex: 1">
          <a-plane
            position="0 0 0"
            height="0.67"
            width="1"
            rotation="0 0 0"
            color="#000000"
          ></a-plane>
          <a-video
            src="#vid-pt"
            position="0 0 0.001"
            height="0.56"
            width="1"
            rotation="0 0 0"
          ></a-video>
          {!muted && (
            <a-image
              src="#btn-v-on"
              class="clickable"
              position={landscape ? "0.55 0.1 0" : "-0.1 -0.45 0"}
              height="0.15"
              width="0.15"
              rotation="0 0 0"
              onClick={() => {
                toggleMute(true);
              }}
            ></a-image>
          )}
          {muted && (
            <a-image
              src="#btn-v-off"
              class="clickable"
              position={landscape ? "0.6 0.1 0" : "-0.1 -0.45 0"}
              height="0.15"
              width="0.15"
              rotation="0 0 0"
              onClick={() => {
                toggleMute(false);
              }}
            ></a-image>
          )}
          <a-image
            src="#btn-link"
            class="clickable"
            position={landscape ? "0.6 -0.1 0" : "0.1 -0.45 0"}
            height="0.15"
            width="0.15"
            rotation="0 0 0"
            onClick={() => {
              openVimeo(WALL_PT_VIDEO_URL);
            }}
          ></a-image>
        </a-entity>
      </a-scene>
      <div className="logos-header" onClick={() => logoClick()}>
        <img src="./assets/images/logo.png" alt="life-ribermine"></img>
        <img src="./assets/images/logo_life.png" alt="life"></img>
      </div>
      {!targetFound && <Scanner />}
      {!loaded && <Loading />}
    </div>
  );
};

export default MindAR;
